import React from 'react';
import './Third.css';

export default function Third() {

    let big={
        background: 'linear-gradient(90deg,#31393A, #094123)'
    }
    let container={
        // margin:'10%',
        padding:'10%'
    }
    let heading ={

       
        color: 'white',
        fontWeight: 'bold', 
        fontSize: '30px',
        textTransform:'uppercase',
        
    }
    let text={
        color:'white',
        opacity:'80%',
        fontWeight:'bold'
    }
    let green={
        color:'#08D665',
        
        fontWeight:'bold'
    }
    let box={
        display:'flex',
        justifyContent:'center',

    }
    let four={
        margin:'3%',
        padding:'20px',
        background:'#0B542C',
        color:'white'


    }
  return (
    <div>
      <div className="big" style={big}>
        <div className="container" style={container}>
            <div className="first" style={green}>MY EXPERIENCE</div>
            <div className="heading" style={heading}>EXPERIENCE AND SKILL</div>
            <div className="text" style={text}>Sonal kumar! Being A Skilled Web Designer Is A Valuable Talent In Today's Digital Age.</div>
            <div className="box" style={box}>
                <div className="four" style={four}><h2 style={{color:'#08D665'}}>Skillzard </h2><p>As a web designer, you have the skills to transform ideas into interactive and engaging digital experiences.</p></div>
                <div className="four" style={four}><h2 style={{color:'#08D665'}}>Cashzard</h2><p>As a web designer, you possess the expertise to design user-friendly interfaces that enhance the user experience.</p></div>
                <div className="four" style={four}><h2 style={{color:'#08D665'}}>Learnfixx</h2><p>As a web designer, you are adept at combining aesthetics with functionality to deliver seamless web solutions.</p></div>

                <div className="four" style={four}><h2 style={{color:'#08D665'}}>Skillvigor</h2><p>As a web designer, you have the capability to craft responsive websites that look great on all devices.</p></div>
            </div>
        </div>
      </div>
    </div>
  )
}
