import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const ContactForm = () => {
  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col md={6}>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24186.511669720733!2d-74.0059721!3d40.7127753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDDCsDQyJzQ1LjkiTiA3NMKwMDAnMjUuNiJX!5e0!3m2!1sen!2sus!4v1597740999478!5m2!1sen!2sus"
            width="100%"
            height="450"
            // frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </Col>
        <Col md={6}>
          <h2 className="mt-4">Get In One Touch</h2>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" />
</Form.Group>
<Form.Group controlId="formEmail">
<Form.Label>Email</Form.Label>
<Form.Control type="email" placeholder="Enter your email" />
</Form.Group>
<Form.Group controlId="formMessage">
<Form.Label>Message</Form.Label>
<Form.Control as="textarea" rows={3} placeholder="Enter your message" />
</Form.Group>
<br/>
<Button variant="success" type="submit">
Send Now
</Button>
</Form>
</Col>
</Row>
</Container>
);
};

export default ContactForm;
