import React from 'react'

export default function Second() {
    let big={
        background:'#2F3438'
    }
    let container={
        // margin:'10%',
        padding:'10%'
    }
    let heading ={

       
        color: 'white',
        fontWeight: 'bold', 
        fontSize: '30px',
        textTransform:'uppercase',
        
    }
    let text={
        color:'white',
        opacity:'80%'
    }
    let button={
        background:'#08D665',
        color:'white',
        padding:'10px',
        borderRadius:'3px',
        border:'none',
        marginTop:'10px'
    }
    let info={
        display:'flex',
        
    marginTop:'3%',
    color:'#08D665',
    fontSize:'13px'
    }
    let inline2={
        marginLeft:'5%'
    }
  return (
    <div>
      <div className="big" style={big}>
        <div className="container" style={container}>
            <div className="heading" style={heading}>HEY IT'S <div style={{color:'#08D665'}}>SONAL KUMAR .</div> I AM A GOOD WEB DESIGNER
            </div>
            <div className="text" style={text}>"I'm a web designer. I create websites that are user-friendly and visually appealing. I have experience in designing websites for various purposes, from business sites to personal portfolios. My goal is to make sure that people enjoy using the websites I design. I'm always exploring new techniques to improve my designs and stay up-to-date with the latest trends in web design."</div>
            <div className="info" style={info}>
                <div className="inline" >Age:19 year <br/> Residence:Delhi <br/> Addres:Badarpur south delhi</div>
                <div className="inline" style={inline2}>Phone:9354548803 <br/> Email:businessaryan77@gmail.com <br/> Website:www.sitebride.online</div>
            </div>
            <div className="button" ><button style={button}>Download Cv</button></div>
        </div>
      </div>
    </div>
  )
}
