import React from 'react';
import Sonal from './sonal.jpg';
import './Front.css';

export default function Front() {

    const container = {
        display: 'flex',
        padding: '10%',
        position: 'relative', // Make the container relative
    };

    const heading = {
        color: 'white',
        fontWeight: 'bold', 
        fontSize: '30px',
        textTransform: 'uppercase',
    };

    const text = {
        color: 'white',
        opacity: '80%',
    };

    const button = {
        background: '#08D665',
        color: 'white',
        padding: '10px',
        borderRadius: '3px',
        border: 'none',
        marginTop: '10px',
    };

    const chitr = {
        height: '400px',
        width: 'auto',
        border: '8px solid #19593F',
        zIndex: 2, // Higher z-index to ensure it is above the SVG
        position: 'relative', // Ensure it respects the z-index
    };

    const textContainer = {
        margin: '10px',
    };

    const svgStyle = {
        position: 'absolute',
        height: '200vh',
        width: '70vh',
        zIndex: 1, // Lower z-index to place it behind the image
        right: '5%',
        top: '-45%', // Ensure it starts from the top
    };

    let Send = () => {
        window.location.href = 'https://wa.me/9354548803';
    }

    return (
        <div className="big" style={{ background: 'black' }}>
            <div className="container" style={container}>
                <div className="small-cont" style={textContainer}>
                    <div className="heading" style={heading}>
                        <div style={{ color: '#08D665' }}>Web</div> development service
                    </div>
                    <div className="text" style={text}>
                        "Hello, I'm Sonal Kumar, a senior full-stack web developer with a proven track record of delivering top-rated websites. With a focus on seamless UI/UX & faster design."
                    </div>
                    <div className="button">
                        <button style={button} onClick={Send}>Start chat</button>
                    </div>
                    
                </div>
                <div className="photo">
                    <img src={Sonal} style={chitr} alt="Sonal" />
                </div>
                <svg viewBox="381 93 320 291" style={svgStyle} width="320" height="291" fill="#56F09F">
                    <path d="M401 195 L491 195 C491 195, 501 195, 501 205 L501 254 C501 254, 501 264, 511 264 L515.01 264 C515.01 264, 525.01 264, 525.01 254 L525.01 244.203354 C525.01 244.203354, 525.01 234.203354, 535.01 234.203354 L571.280214 234.203354 C571.280214 234.203354, 581.280214 234.203354, 581.280214 224.203354 L581.280214 213.140107 C581.280214 213.140107, 581.280214 213, 581.140107 213 L581.140107 213 C581.140107 213, 581 213, 581 212.859893 L581 113 C581 113, 581 103, 591 103 L681 103 C681 103, 691 103, 691 113 L691 203 C691 203, 691 213, 681 213 L668.989076 213 C668.989076 213, 658.989076 213, 658.989076 223 L658.989076 226.883108 C658.989076 226.883108, 658.989076 236.883108, 648.989076 236.883108 L596.641108 236.883108 C596.641108 236.883108, 586.641108 236.883108, 586.641108 246.883108 L586.641108 266.984246 C586.641108 266.984246, 586.641108 276.984246, 596.641108 276.984246 L647.742138 276.984246 C647.742138 276.984246, 657.742138 276.984246, 657.742138 286.984246 L657.742138 309.98274 C657.742138 309.98274, 657.742138 319.98274, 647.742138 319.98274 L596.641108 319.98274 C596.641108 319.98274, 586.641108 319.98274, 586.641108 329.98274 L586.641108 348.60606199999995 C586.641108 348.60606199999995, 586.641108 349.42661599999997, 585.820554 349.42661599999997 L585.820554 349.42661599999997 C585.820554 349.42661599999997, 585 349.42661599999997, 585 350.24717 L585 364 C585 364, 585 374, 575 374 L485 374 C485 374, 475 374, 475 364 L475 315 C475 315, 475 305, 465 305 L401 305 C401 305, 391 305, 391 295 L391 205 C391 205, 391 195, 401 195"></path>
                </svg>
            </div>
        </div>
    );
}
