import React from 'react';
import contactImage from './contact.jpeg';
import mail from './mail.svg';
import call from './call.svg';
import location from './location.svg';
import Send from './Send';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Contact.css';


export default function Contact() {
  // CSS styles for the image
  const imageStyle = {
    width: '100%',
    height: 'auto'
  };

  // Container style to demonstrate the image covering full width
  const containerStyle = {
    width: '100%',
    overflow: 'hidden'
  };

  let text={
    fontWeight:'bold',
    fontSize:'50px',
    position:'absolute',
    top:'40%',
    left:'20px',
    color:'white'


  }
  let mini={
    fontWeight:'bold',
    fontSize:'20px',
    position:'absolute',
    top:'50%',
    left:'25px',
    color:'white'
  }

  let box={
    padding:'2%',
    display:'flex',
    justifyContent:'center',


  }
  let three={
    padding:'30px',
    boxShadow: '0px 4px 8px rgba(211, 211, 211, 0.5)',
    borderRadius:'6px',
    textAlign:'center',
    margin:'4%',
    fontSize:'25px',
    fontWeight:'bold',
    width:'300px',
    height:'180px'

  }
  let small={
    fontSize:'15px',
    opacity:'70%'

   
  }
  return (
    <div style={containerStyle}>

      <div className="both">
        <div className="text" style={text}>CONTACT US</div>
        <div className="mini" style={mini}>We support you</div>
        </div>
        
      <img src={contactImage} style={imageStyle} alt="Contact" />
      

  <div className="box" style={box}>
    <div className="three" style={three}><img src={location} style={{height:'40px',width:'auto'}}/><br/>Addres line  <div className="small" style={small}>Badarpur New Delhi </div></div>
    <div className="three" style={three}><img src={call} style={{height:'40px',width:'auto'}} /><br/>Phone <div className="small" style={small}>+91 9354548803 </div></div>
    <div className="three" style={three}><img src={mail} style={{height:'40px',width:'auto'}} /><br/>Mail <div className="small" style={small}> businessaryan77@gmail.com </div></div>
  </div>

  <Send/>
  <br/>

    </div>

    
  );
}

