import React, { useState } from 'react';
import './Navbar.css'; // Import the CSS file for styling
import sitebride from './sitebride.png';

import{
  Link
} from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  let logo={
    height:'50px'
  }

  return (
    <nav className="navbar">
      <div className="navbar-logo"><img src={sitebride} style={logo}/></div>
      <button className="navbar-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <ul className={`navbar-links ${isOpen ? 'active' : ''}`}>
        <li className="navbar-item"><Link to="/" className="navbar-link">Home</Link></li>
        <li className="navbar-item"><Link to="/about" className="navbar-link">About</Link></li>
        <li className="navbar-item"><Link to="/contact" className="navbar-link">Contact</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;
