import React from 'react';
import Front from './MyComponents/Front';
import Second from './MyComponents/Second';
import Third from './MyComponents/Third';
import Navbar from './MyComponents/Navbar';
import Contact from './MyComponents/Contact';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";



function App() {
  return (
    <Router>
    <>
    <Navbar/>
    <Routes>

      <Route exact path="/" element={<Front />}/>
      <Route exact path="/Contact" element={<Contact />}/>
      
      </Routes>
      <Second/>
      <Third/>
    </>
    </Router>
  );
}

export default App;
